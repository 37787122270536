// User.js

import Contract from './Contract'; // Import the Contract model
import Customer from './Customer';
import Invoice from './Invoice';

import axios from 'axios';

class User {
    constructor(data, ApiCall) {
        this.id = data.id;
        this.email = data.email;
        this.password = data.password;
        this.latestActivity = data.latestActivity ? new Date(data.latestActivity) : null;
        this.createdAt = new Date(data.createdAt);
        this.updatedAt = new Date(data.updatedAt);
        this.twoFactorEnabled = data.twoFactorEnabled;
        this.ApiCall = ApiCall; // Store the ApiCall function
    }

    getId() {
        return this.id;
    }

    getEmail() {
        return this.email;
    }

    getPassword() {
        return this.password;
    }

    getLatestActivity() {
        return this.latestActivity;
    }

    getCreatedAt() {
        return this.createdAt.toLocaleString();
    }

    getUpdatedAt() {
        return this.updatedAt.toLocaleString();
    }

    async getContracts({ sortField, sortOrder, searchTerm, page, limit }) {
        try {
            const response = await this.ApiCall("GET", `contracts?sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}&page=${page}&limit=${limit}`, null);
            const contracts = await Promise.all(response.contracts.map(async contractData => {
                const contract = new Contract(contractData, this.ApiCall);
                return contract;
            }));
            return {
                contracts,
                totalPages: response.totalPages
            };
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getErrors({ sortField, sortOrder, searchTerm, page, limit }) {
        try {
            const response = await this.ApiCall("GET", `contracts/errors?sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}&page=${page}&limit=${limit}`, null);
            const contracts = await Promise.all(response.contracts.map(async contractData => {
                const contract = new Contract(contractData, this.ApiCall);
                return contract;
            }));
            return {
                contracts,
                totalPages: response.totalPages
            };
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getContractById(contractId) {
        try {
            const response = await this.ApiCall("GET", `contracts/${contractId}`, null);
            const contract = new Contract(response, this.ApiCall);
            return contract;
        } catch (error) {
            console.error(`Failed to fetch contract with ID ${contractId}:`, error);
            throw error;
        }
    }

    async getCustomers() {
        try {
            const response = await this.ApiCall("GET", `customers`, null);
            const customers = await Promise.all(response.map(async customerData => {
                const customer = new Customer(customerData);
                return customer;
            }));
            return {
                customers
            };
        } catch (error) {
            console.error('Failed to fetch customers:', error);
            throw error;
        }
    }

    saveToLocalStorage() {
        localStorage.setItem('user', JSON.stringify({
            id: this.id,
            name: this.name,
            email: this.email,
            token: this.token
        }));
    }

    static loadFromLocalStorage(ApiCall) {
        const userData = localStorage.getItem('user');
        return userData ? new User(JSON.parse(userData), ApiCall) : null;
    }

    static clearLocalStorage() {
        localStorage.removeItem('user');
    }

    getToken() {
        return this.token;
    }

    getTwoFactorEnabled() {
        return this.twoFactorEnabled;
    }

    async getUsers() {
        try {
            const response = await this.ApiCall("GET", `users`, null);
            const users = await Promise.all(response.map(async userData => {
                const user = new User(userData, this.ApiCall);
                return user;
            }
            ));
            return {
                users
            };
        } catch (error) {
            console.error('Failed to fetch users:', error);
            throw error;
        }
    }

    async createUser(data) {
        try {
            const response = await this.ApiCall("POST", `users`, data);
            return response;
        } catch (error) {
            console.error('Failed to create user:', error);
            throw error;
        }
    }

    async changePassword(userId, data) {
        try {
            const response = await this.ApiCall("PUT", `users/${userId}`, data);
            return response;
        } catch (error) {
            console.error('Failed to change password:', error);
            throw error;
        }
    }

    async addContract(data) {
        try {
            const dataArray = Array.isArray(data) ? data : [data];
            const response = await this.ApiCall("POST", `contracts`, dataArray);
            return response;
        } catch (error) {
            console.error('Failed to add contract:', error);
            throw error;
        }
    }

    async modifyContract(contractId, data) {
        try {
            const response = await this.ApiCall("PUT", `contracts/${contractId}`, data);
            return response;
        } catch (error) {
            console.error('Failed to modify contract:', error);
            throw error;
        }
    }

    async getStatistics() {
        try {
            const response = await this.ApiCall("GET", `statistics`, null);
            return response;
        } catch (error) {
            console.error('Failed to fetch statistics:', error);
            throw error;
        }
    }

    async getBusinessData(businessId) {
        return new Promise((resolve, reject) => {
            axios({
                method: "GET",
                url: "https://avoindata.prh.fi/opendata-ytj-api/v3/companies?businessId=" + businessId,
            })
                .then((res) => {
                    const { data } = res || {}; // Safe destructuring
    
                    function parseCompanyData(data) {
                        if (!data || !data.companies || data.companies.length === 0) {
                            return null;
                        }
    
                        const company = data.companies[0];
                        const name = company.names && company.names.length > 0 ? company.names[0].name : '';
                        const addressObj = company.addresses && company.addresses.length > 0 ? company.addresses[0] : {};
                        const address = [
                            addressObj.street,
                            addressObj.buildingNumber,
                            addressObj.entrance,
                            addressObj.apartmentNumber
                        ].filter(Boolean).join(' ') || '';
                        const postalCode = addressObj.postCode || '';
                        const cityObj = addressObj.postOffices && addressObj.postOffices.length > 0 ? addressObj.postOffices[0] : {};
                        const city = cityObj.city || '';
                        const co = addressObj.co || '';

                        return {
                            name,
                            address,
                            postalCode,
                            city,
                            co
                        };
                    }
    
                    const parsedData = parseCompanyData(data);
                    resolve(parsedData);
                })
                .catch((err) => {
                    console.error(err.response);
                    reject(err);
                });
        });
    }

    async getAllInvoices(date, page = 1) {
        try {
            const queryParams = `?date=${date}&page=${page}`;
            const response = await this.ApiCall("GET", `invoices${queryParams}`, null);
            const invoices = await Promise.all(response.invoices.map(async invoiceData => {
                const invoice = new Invoice(invoiceData, this.ApiCall);
                return invoice;
            }));
            return {
                invoices,
                totalPages: response.totalPages, 
                totalInvoices: response.totalInvoices
            };
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
            throw error;
        }
    }
    
    async getUnpaidInvoices(date, page = 1) {
        try {
            const queryParams = `?date=${date}&page=${page}`;
            const response = await this.ApiCall("GET", `invoices/unpaid${queryParams}`, null);
            const invoices = await Promise.all(response.invoices.map(async invoiceData => {
                const invoice = new Invoice(invoiceData, this.ApiCall);
                return invoice;
            }));
            return {
                invoices,
                totalPages: response.totalPages, 
                totalInvoices: response.totalInvoices
            };
        } catch (error) {
            console.error('Failed to fetch unpaid invoices:', error);
            throw error;
        }
    }
    
    async getPaidInvoices(date, page = 1) {
        try {
            const queryParams = `?date=${date}&page=${page}`;
            const response = await this.ApiCall("GET", `invoices/paid${queryParams}`, null);
            const invoices = await Promise.all(response.invoices.map(async invoiceData => {
                const invoice = new Invoice(invoiceData, this.ApiCall);
                return invoice;
            }));
            return {
                invoices,
                totalPages: response.totalPages, 
                totalInvoices: response.totalInvoices
            };
        } catch (error) {
            console.error('Failed to fetch paid invoices:', error);
            throw error;
        }
    }

    async getInvoiceSummary(date) {
        try {
            const queryParams = `?date=${date}`;
            const response = await this.ApiCall("GET", `invoices/summary${queryParams}`, null);
            return {
                totalInvoices: response.totalInvoices,
                paidInvoices: response.paidInvoices,
                unpaidInvoices: response.unpaidInvoices
            };
        } catch (error) {
            console.error('Failed to fetch invoice summary:', error);
            throw error;
        }
    }


}

export default User;