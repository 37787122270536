import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainView from '../Components/Main/MainView';
import Login from '../Components/Login/Login';
import Navbar from '../Components/Main/Navbar';
import PrivateRoute from './PrivateRoute';
import ContractList from '../Components/Contracts/ContractList';
import Setup2FA from '../Components/Login/Setup2FA';
import Verify2FA from '../Components/Login/Verify2FA';
import UserList from '../Components/Users/UserList';
import Offer from '../Components/Offer/Offer';
import CustomerList from '../Components/Customers/CustomerList';
import InvoiceList from '../Components/Invoices/InvoiceList';

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/setup2fa" element={<Setup2FA />} />
            <Route path="/verify2fa" element={<Verify2FA />} />
            <Route
                path="/"
                element={<PrivateRoute element={<MainView />} />}
            />
            <Route
                path="/contracts"
                element={<PrivateRoute element={<ContractList />} />}
            />
            <Route
                path="/invoices"
                element={<PrivateRoute element={<InvoiceList />} />}
            />
            <Route
                path="/users"
                element={<PrivateRoute element={<UserList />} />}
            />
            <Route
                path="/offers"
                element={<PrivateRoute element={<Offer />} />}
            />
        </Routes>
    );
};

export default AppRouter;