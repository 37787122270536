import React, { createContext, useState, useEffect, useContext } from 'react';
import User from '../Models/User';
import { ApiContext } from './ApiContext';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tempEmail, setTempEmail] = useState(null);
    const [tempPassword, setTempPassword] = useState(null);
    const context = useContext(ApiContext);
    const { ApiCall } = context || {};

    useEffect(() => {
        const storedUser = User.loadFromLocalStorage(ApiCall);
        if (storedUser) {
            setUser(storedUser);
        }
        setLoading(false);
    }, [ApiCall]);

    const login = (userData) => {
        const user = new User(userData, ApiCall);
        user.saveToLocalStorage();
        setUser(user);
    };

    const logout = () => {
        User.clearLocalStorage();
        setUser(null);
        document.cookie = "token-billing=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = '/login';
    };

    const setTempData = (email, password) => {
        setTempEmail(email);
        setTempPassword(password);
    };

    const clearTempData = () => {
        setTempEmail(null);
        setTempPassword(null);
    };

    return (
        <UserContext.Provider value={{ user, loading, login, logout, tempEmail, tempPassword, setTempData, clearTempData }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useUser = () => {
    return useContext(UserContext);
};