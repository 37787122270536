// Contract.js

import Customer from './Customer';
import Invoice from './Invoice';
import Row from './Row';

class Contract {
    constructor(data, ApiCall) {
        this.id = data.id;
        this.customerId = data.customerId;
        this.description = data.description;
        this.startingDate = new Date(data.startingDate);
        this.nextInvoicesDate = new Date(data.nextInvoicesDate);
        this.endingDate = data.endingDate ? new Date(data.endingDate) : null;
        this.invoicingInterval = data.invoicingInterval;
        this.createdAt = new Date(data.createdAt);
        this.updatedAt = new Date(data.updatedAt);
        this.customer = data.customer ? new Customer(data.customer) : null;
        this.totalPrice = data.totalPrice;
        this.ApiCall = ApiCall;
        this.rows = []; // Initialize rows as an empty array
        this.errorMessage = data.errorMessage;
    }

    getId() {
        return this.id;
    }

    getCustomerId() {
        return this.customerId;
    }

    getDescription() {
        return this.description;
    }

    getStartingDate() {
        return this.startingDate;
    }

    getNextInvoicesDate() {
        return this.nextInvoicesDate;
    }

    getEndingDate() {
        if (!this.endingDate || isNaN(this.endingDate.getTime())) {
            return null;
        }
        return this.endingDate;
    }

    getInvoicingInterval() {
        return this.invoicingInterval;
    }

    getCreatedAt() {
        return this.createdAt;
    }

    getUpdatedAt() {
        return this.updatedAt;
    }

    getCustomer() {
        return this.customer;
    }

    getTotalPrice() {
        return parseFloat(this.totalPrice).toFixed(2);
    }

    async getRows() {
        try {
            const response = await this.ApiCall("GET", `rows?contractId=${this.getId()}`, null);
            const rows = await Promise.all(response.map(async rowData => {
                const row = new Row(rowData);
                return row;
            }));
            return rows;
        } catch (error) {
            console.error("Failed to fetch rows:", error);
            throw error;
        }
    }

    getErrorMessage() {
        return this.errorMessage;
    }

    async getInvoices(page) {
        try {
            const response = await this.ApiCall("GET", `invoices?contractId=${this.getId()}&page=${page}&pageSize=5`, null);
            const invoices = await Promise.all(response.invoices.map(async invoiceData => {
                const invoice = new Invoice(invoiceData, this.ApiCall);
                return invoice;
            }));
            return {
                invoices,
                totalPages: response.totalPages
            };
        } catch (error) {
            console.error("Failed to fetch invoices:", error);
            throw error;
        }
    }

    async getLatestInvoice() {
        try {
            const response = await this.ApiCall("GET", `invoices?contractId=${this.getId()}`, null);
            if (response.invoices.length === 0) {
                return null;
            }
            return new Invoice(response.invoices[0], this.ApiCall);
        } catch (error) {
            console.error("Failed to fetch the latest invoice:", error);
            throw error;
        }
    }
}

export default Contract;