// Invoice.js

import InvoiceRow from "./InvoiceRow";
import Customer from "./Customer";

class Invoice {
    constructor(data, ApiCall) {
        this.id = data.id;
        this.contractId = data.contractId;
        this.customerId = data.customerId;
        this.dueDate = new Date(data.dueDate);
        this.createdAt = new Date(data.createdAt);
        this.description = data.description;
        this.updatedAt = new Date(data.updatedAt);
        this.fennoaInvoiceId = data.fennoaInvoiceId;
        this.paymentStatus = data.paymentStatus;
        this.ApiCall = ApiCall;
        this.invoiceRows = data.invoiceRows;
        this.totalDue = data.totalDue;
        this.customer = data.customer ? new Customer(data.customer) : null;
    }

    getId() {
        return this.id;
    }

    getContractId() {
        return this.contractId;
    }

    getCustomerId() {
        return this.customerId;
    }

    getDueDate() {
        return this.dueDate;
    }

    getCreatedAt() {
        return this.createdAt;
    }

    getDescription() {
        return this.description;
    }

    getUpdatedAt() {
        return this.updatedAt;
    }

    getFennoaInvoiceId() {
        return this.fennoaInvoiceId;
    }

    getPaymentStatus() {
        return this.paymentStatus;
    }

    getInvoiceRows() {
        return this.invoiceRows.map(row => new InvoiceRow(row));
    }

    getTotalDue() {
        return parseFloat(this.totalDue).toFixed(2);
    }

    getCustomer() {
        return this.customer;
    }
}

export default Invoice;