import axios from 'axios';
import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import User from '../../Models/User';
import { UserContext, useUser } from '../../Utils/UserContext';
import { ApiContext } from '../../Utils/ApiContext';
import { Alert, Box, Button, TextField, Typography, useMediaQuery } from '@mui/material';
import ColorScheme from '../../Styles/ColorScheme';
import SixFigureInput from './SixFigureInput';



const Setup2FA = () => {
    const context = useContext(ApiContext);
    const { ApiCall } = context || {};
    const [qrCode, setQrCode] = useState('');
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const { tempEmail, tempPassword, clearTempData } = useUser();
    const { login } = useContext(UserContext);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const isTabletOrBelow = useMediaQuery('(max-width:768px)');



    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");

        for (let cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    };

    useEffect(() => {
        if (tempEmail && tempPassword) {
            // Fetch 2FA QR code and secret
            axios.post('/api/setup-2fa', { email: tempEmail, password: tempPassword }).then(response => {
                setQrCode(response.data.qrCodeUrl);
            }).catch(error => {
                console.error('Error setting up 2FA:', error);
            });
        }
    }, [tempEmail, tempPassword, clearTempData]);

    const handleVerifyCode = async () => {
        if (!tempEmail || !tempPassword || !twoFactorCode) {
            setShowError(true);
            return;
        }
        try {
            // Lähetä käyttäjän syöttämä koodi backendille vahvistettavaksi
            const response = await axios.post('/api/verify-2fa', { email: tempEmail, password: tempPassword, token: twoFactorCode });
            if (response.status === 200) {
                await deleteAllCookies(); // Corrected function call
                document.cookie = "token-billing=" + response.data.access_token + "; path=/";
                const user = new User(response.data.user, ApiCall); // Pass ApiCall to User
                user.saveToLocalStorage(); // Save user to local storage
                login(user)
                navigate('/contracts');
                clearTempData();
            }
        } catch (error) {
            setShowError(true);
            console.error('Error verifying 2FA:', error);
        }
    };

    const handleVerificationCancel = async () => {
        if (!tempEmail || !tempPassword) {
            setShowError(true);
            return;
        }
        try {
            // Lähetä käyttäjän syöttämä koodi backendille vahvistettavaksi
            const response = await axios.post('/api/remove-2fa', { email: tempEmail, password: tempPassword });
            if (response.status === 200) {
                navigate('/login');
                clearTempData();
            }
        } catch (error) {
            setShowError(true);
            console.error('Error verifying 2FA:', error);
            clearTempData();
        }
    }

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Typography sx={{ marginTop: "20px", marginBottom: "40px" }} variant="h1">{"Aktivoi kaksivaiheinen tunnistautuminen"}</Typography>

            {qrCode && <Box sx={{ p: "10px", border: "1px solid", borderColor: ColorScheme.primary, borderRadius: "4px", marginBottom: "20px" }}>
                <img src={qrCode} alt="Scan QR code" />
            </Box>}
            {showError && <Alert severity="error" sx={{ marginBottom: "10px", alignItems: "center", display: "flex" }}>
                <Typography>
                    {"Koodi on virheellinen."}
                </Typography>
            </Alert>}
            <SixFigureInput value={twoFactorCode} onChange={setTwoFactorCode} />
            <Box sx={{ display: "flex", flexDirection: "row", width: isTabletOrBelow ? "90%" : "40%" }}>
                <Button variant="contained" sx={{ backgroundColor: "transparent", color: ColorScheme.primary, my: "20px", p: "10px", width: "49%", marginRight: "5px" }} onClick={handleVerificationCancel}>
                    <Typography variant='h6' textTransform={"none"}>
                        {"Takaisin"}
                    </Typography>
                </Button>
                <Button variant="contained" sx={{ backgroundColor: ColorScheme.primary, my: "20px", p: "10px", width: "49%", marginLeft: "5px" }} onClick={handleVerifyCode}>
                    <Typography variant='h6' textTransform={"none"}>
                        {"Vahvista koodi"}
                    </Typography>
                </Button>

            </Box>
        </Box>
    );
};

export default Setup2FA;
