
import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, Tooltip, CircularProgress } from '@mui/material';
import { useUser } from '../../Utils/UserContext';
import { useState } from 'react';
import ColorScheme from '../../Styles/ColorScheme';
import { useLocation } from 'react-router-dom';

const InvoiceList = () => {

    const { user } = useUser();

    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const { month, paymentStatus } = location.state || {};

    const [Invoices, setInvoices] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [allInvoicesTotal, setAllInvoicesTotal] = useState(0);
    const [unpaidInvoicesTotal, setUnpaidInvoicesTotal] = useState(0);
    const [paidInvoicesTotal, setPaidInvoicesTotal] = useState(0);

    const [selectedFilter, setSelectedFilter] = useState(0);
    const [selectedInterval, setSelectedInterval] = useState(0);

    const monthsInFinnish = [
        "Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu",
        "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"
    ];

    const getCurrentDate = () => {
        const today = new Date();
        today.setMonth(today.getMonth() - selectedInterval);
        return today.toISOString();
    };

    const getLast12Months = () => {
        const result = [];
        const today = new Date();
        for (let i = 0; i < 12; i++) {
            const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
            const monthName = monthsInFinnish[date.getMonth()];
            const year = date.getFullYear().toString();
            result.push(`${monthName} ${year}`);
        }
        return result;
    };

    const fetchAllInvoices = async () => {
        setLoading(true);
        const currentDate = getCurrentDate();
        try {
            user.getAllInvoices(currentDate, page).then((response) => {
                setInvoices(response.invoices);
                setTotalPages(response.totalPages);
                setLoading(false)
            });
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
            setLoading(false)
        }
    };

    const fetchPaidInvoices = async () => {
        setLoading(true);
        const currentDate = getCurrentDate();
        try {
            user.getPaidInvoices(currentDate, page).then((response) => {
                setInvoices(response.invoices);
                setTotalPages(response.totalPages);
                setLoading(false)
            });
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
            setLoading(false)
        }
    };

    const fetchUnpaidInvoices = async () => {
        setLoading(true);
        const currentDate = getCurrentDate();
        try {
            user.getUnpaidInvoices(currentDate, page).then((response) => {
                setInvoices(response.invoices);
                setTotalPages(response.totalPages);
                setLoading(false)
            });
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
            setLoading(false)
        }
    };

    const fetchSummary = async () => {
        setLoading(true);
        const currentDate = getCurrentDate();
        try {
            user.getInvoiceSummary(currentDate).then((response) => {
                setAllInvoicesTotal(response.totalInvoices);
                setPaidInvoicesTotal(response.paidInvoices);
                setUnpaidInvoicesTotal(response.unpaidInvoices);
                setLoading(false)
                });
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
            setLoading(false)
        }
    };

    useEffect(() => {
        setLoading(true)
        if (month) {
            const finnishMonths = getLast12Months();
            const monthIndex = finnishMonths.indexOf(month);
            if (monthIndex !== -1) {
                setSelectedInterval(monthIndex);
            }
        }
        else {
            setSelectedInterval(0);
        }
        if (paymentStatus !== undefined) {
            setSelectedFilter(paymentStatus === 1 ? 1 : 2);
        } else {
            setSelectedFilter(0);
        }
        setLoading(false)
    }, [month, paymentStatus]);

    useEffect(() => {
        if (!month && !paymentStatus) {
            selectedFilter == 0 ? fetchAllInvoices() : selectedFilter == 1 ? fetchPaidInvoices() : fetchUnpaidInvoices();
            fetchSummary();
        }
    }, []);

    useEffect(() => {
        selectedFilter == 0 ? fetchAllInvoices() : selectedFilter == 1 ? fetchPaidInvoices() : fetchUnpaidInvoices();
    }, [page]);
    
    useEffect(() => {
        setPage(1);
        selectedFilter == 0 ? fetchAllInvoices() : selectedFilter == 1 ? fetchPaidInvoices() : fetchUnpaidInvoices();
    }, [selectedFilter]);

    useEffect(() => {
        setPage(1);
        selectedFilter == 0 ? fetchAllInvoices() : selectedFilter == 1 ? fetchPaidInvoices() : fetchUnpaidInvoices();
        fetchSummary();
    }, [selectedInterval]);

    

    const renderInvoiceRows = (invoice) => {
        return (
            <Box>
                {invoice.getInvoiceRows().map((row, index) => (
                    <Typography color={ColorScheme.black} key={index} variant='body1'>
                        {row.description}: {row.price} €
                    </Typography>
                ))}
            </Box>
        );
    };

    return (
        <Container sx={{ paddingBottom: "20px", paddingTop: "10px", display: "flex", flexDirection: "column"}}>
            <Typography variant="h2">{"Lähetetyt laskut"}</Typography>
            {!loading ? <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <Box sx={{ marginTop: "20px", width: "20%"  }}>
                    <Button onClick={() => setSelectedFilter(0)} sx={{mb: "5px", alignItems: "flex-start", width: "100%", display: "flex", backgroundColor: selectedFilter == 0 ? ColorScheme.primary_opacity : ColorScheme.lightGrey, color: selectedFilter != 0 ? ColorScheme.grey : ColorScheme.black, flexDirection: "column", border: "1px solid " + (selectedFilter != 0 ? ColorScheme.border : ColorScheme.primary), padding: "10px", borderRadius: "5px" }}>
                        <Typography textTransform={"none"} variant="h5">{"Kaikki laskut (" + allInvoicesTotal + ")"}</Typography>
                    </Button>
                    <Button onClick={() => setSelectedFilter(1)} sx={{my: "5px", alignItems: "flex-start", width: "100%", backgroundColor: selectedFilter == 1 ? ColorScheme.green_opacity : ColorScheme.lightGrey, color: selectedFilter != 1 ? ColorScheme.grey : ColorScheme.black, flexDirection: "column", border: "1px solid " + (selectedFilter != 1 ? ColorScheme.border : ColorScheme.green), padding: "10px", borderRadius: "5px"}}>
                        <Typography textAlign={"left"} textTransform={"none"} variant="h5">{"Maksettu (" + paidInvoicesTotal + ")"}</Typography>
                    </Button>
                    <Button onClick={() => setSelectedFilter(2)} sx={{my: "5px", alignItems: "flex-start", width: "100%", display: "flex", backgroundColor: selectedFilter == 2 ? ColorScheme.red_opacity : ColorScheme.lightGrey, color: selectedFilter != 2 ? ColorScheme.grey : ColorScheme.black, flexDirection: "column", border: "1px solid " + (selectedFilter != 2 ? ColorScheme.border : ColorScheme.red), padding: "10px", borderRadius: "5px" }}>
                        <Typography textTransform={"none"} variant="h5">{"Maksamatta (" + unpaidInvoicesTotal + ")"}</Typography>
                    </Button>

                    <Typography sx={{marginTop: "20px"}} variant="h3">{"Suodattimet"}</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                            {getLast12Months().map((month, index) => (
                                <Button onClick={() => setSelectedInterval(index)} sx={{backgroundColor: selectedInterval == index ? ColorScheme.primary : "transparent"}} key={index} variant="contained">
                                    <Typography color={selectedInterval == index ? ColorScheme.white : ColorScheme.black} textTransform={"none"} variant="body2">{month}</Typography>
                                </Button>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ marginTop: "20px", width: "1px", backgroundColor: ColorScheme.border}}/>
                <Box sx={{ marginTop: "20px", width: "75%" }}>
                    {Invoices.length > 0 ? Invoices.map((invoice) => {
                        return (
                            <Tooltip key={invoice.id} placement='top' followCursor title={renderInvoiceRows(invoice)} >
                                <Box key={invoice.getId()} sx={{ border: "1px solid " + (invoice.getPaymentStatus() == 1 ? ColorScheme.green_opacity : ColorScheme.red_opacity), display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "10px", backgroundColor: ColorScheme.lightGrey, borderRadius: "5px", marginBottom: "10px" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "15%" }}>
                                        <Typography variant="h5">{invoice.getCustomer().getName()}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
                                        <Typography variant="body2">{invoice.getDescription()}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "10%" }}>
                                        <Typography variant="h6">{"Eräpäivä"}</Typography>
                                        <Typography variant="h6">{invoice.getDueDate().toLocaleDateString()}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "10%" }}>
                                        <Typography variant="h5">{invoice.getTotalDue() + "€"}</Typography>
                                        <Typography color={invoice.getPaymentStatus() == 1 ? ColorScheme.green : ColorScheme.red} variant="h6">{invoice.getPaymentStatus() == 1 ? "Maksettu" : "Maksamatta"}</Typography>
                                    </Box>
                                </Box>
                            </Tooltip>
                            
                        );
                    }) : 
                    <Typography sx={{my: "30px"}} textAlign={"center"} variant="h4">{"Ei laskuja valitulla aikavälillä"}</Typography>}
                    {totalPages > 0 && <Box sx={{ display: "flex", justifyContent: "center", mt: "20px", flexDirection: "row", alignItems: "center" }}>
                        <Button
                            onClick={() => setPage(page - 1)}
                            disabled={page === 1}
                            variant='outlined'
                            sx={{
                                borderColor: ColorScheme.border,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: ColorScheme.lightGrey,
                                color: ColorScheme.primary,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <Typography textTransform={"none"} variant='body1'>
                                Edellinen
                            </Typography>
                        </Button>

                        <Typography variant='body1' sx={{ mx: "20px" }}>
                            Sivu {page} / {totalPages}
                        </Typography>

                        <Button
                            onClick={() => setPage(page + 1)}
                            disabled={page === totalPages || totalPages === 0}
                            variant='outlined'
                            sx={{
                                borderColor: ColorScheme.border,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: ColorScheme.lightGrey,
                                color: ColorScheme.primary,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <Typography textTransform={"none"} variant='body1'>
                                Seuraava
                            </Typography>
                        </Button>
                    </Box>}
                </Box>
            </Box> : <Box sx={{ py: "100px", height: "100%", width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <CircularProgress sx={{ color: ColorScheme.primary }} />
                </Box>}
        </Container>
    );
};

export default InvoiceList;