import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext';

const useAuthRedirect = () => {
    const { user, loading } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== '/setup2fa' && location.pathname !== '/verify2fa' && location.pathname !== '/login') {
            if (loading) {
                console.log('Loading user data...');
                return; // Do nothing while loading
            }

            const checkAuthToken = () => {
                const token = document.cookie.split('; ').find(row => row.startsWith('token-billing='));
                return token ? token.split('=')[1] : null;
            };

            const authToken = checkAuthToken();

            if (!user || !authToken) {
                console.log('Redirecting to login');
                navigate('/login', { replace: true });
            }
        }
    }, [loading, navigate]); // Removed user from dependency array to avoid unnecessary re-renders
};

export default useAuthRedirect;