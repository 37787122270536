// Row.js

class Row {
    constructor(data) {
        this.id = data.id;
        this.contractId = data.contractId;
        this.description = data.description;
        this.amount = data.amount;
        this.price = data.price;
        this.unitPrice = data.unitPrice;
        this.unit = data.unit;
        this.vatPercentage = data.vatPercentage;
        this.discountPercentage = data.discountPercentage;
        // Add other properties as needed
    }

    getId() {
        return this.id;
    }

    getContractId() {
        return this.contractId;
    }

    getDescription() {
        return this.description;
    }

    getAmount() {
        return this.amount;
    }

    getPrice() {
        return parseFloat(this.price).toFixed(2);
    }

    getUnitPrice() {
        return parseFloat(this.unitPrice).toFixed(2);
    }

    getUnit() {
        return this.unit;
    }

    getVatPercentage() {
        return this.vatPercentage;
    }

    getDiscountPercentage() {
        return this.discountPercentage;
    }

}

export default Row;