import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import User from '../../Models/User';
import { UserContext, useUser } from '../../Utils/UserContext';
import { ApiContext } from '../../Utils/ApiContext';
import { Alert, Box, Button, Typography, useMediaQuery } from '@mui/material';
import SixFigureInput from './SixFigureInput';
import ColorScheme from '../../Styles/ColorScheme';
import axios from 'axios';

const Verify2FA = () => {
    const context = useContext(ApiContext);
    const { ApiCall } = context || {};
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const { tempEmail, tempPassword, clearTempData } = useUser();
    const { login } = useContext(UserContext);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);

    const isTabletOrBelow = useMediaQuery('(max-width:768px)');

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");

        for (let cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    };

    const handleVerifyCode = async () => {
        if (!tempEmail || !tempPassword || !twoFactorCode) {
            setShowError(true);
            return;
        }
        try {
            // Lähetä 2FA-koodi backendille
            const response = await axios.post('/api/verify-2fa', { email: tempEmail, password: tempPassword, token: twoFactorCode });

            if (response.status === 200) {
                await deleteAllCookies(); // Corrected function call
                document.cookie = "token-billing=" + response.data.access_token + "; path=/";
                const user = new User(response.data.user, ApiCall); // Pass ApiCall to User
                user.saveToLocalStorage(); // Save user to local storage
                login(user)
                navigate('/contracts');
                clearTempData();
            }
        } catch (error) {
            setShowError(true);
            console.error('Error verifying 2FA:', error);
        }
    };

    return (

        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Typography sx={{ marginTop: "20px", marginBottom: "20px" }} variant="h1">{"Syötä koodi"}</Typography>
            {showError && <Alert severity="error" sx={{ marginBottom: "10px", alignItems: "center", display: "flex" }}>
                <Typography>
                    {"Koodi on virheellinen."}
                </Typography>
            </Alert>}
            <SixFigureInput value={twoFactorCode} onChange={setTwoFactorCode} />
            <Box sx={{ display: "flex", flexDirection: "row", width: isTabletOrBelow ? "90%" : "40%" }}>
                <Button variant="contained" sx={{ backgroundColor: "transparent", color: ColorScheme.primary, my: "20px", p: "10px", width: "49%", marginRight: "5px" }} onClick={() => navigate("/login")}>
                    <Typography variant='h6' textTransform={"none"}>
                        {"Takaisin"}
                    </Typography>
                </Button>
                <Button variant="contained" sx={{ backgroundColor: ColorScheme.primary, my: "20px", p: "10px", width: "49%", marginLeft: "5px" }} onClick={handleVerifyCode}>
                    <Typography variant='h6' textTransform={"none"}>
                        {"Vahvista koodi"}
                    </Typography>
                </Button>

            </Box>

        </Box>

    );
};

export default Verify2FA;
