// src/components/Navbar.js
import React, { useContext, useEffect } from 'react';
import { AppBar, Toolbar, Drawer, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Utils/UserContext';
import logo from '../../Assets/tolotech_logo_white_new_text.png';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import { Paper, List } from '@mui/material';
import { ReactComponent as LoginIcon } from "../../Assets/login-logout-arrow.svg";
import ColorScheme from '../../Styles/ColorScheme';
import './Navbar.css';

const Navbar = () => {

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const { user } = useContext(UserContext)

    const { logout } = useContext(UserContext);
    const navigate = useNavigate();

    const checkAuthToken = () => {
        const token = document.cookie.split('; ').find(row => row.startsWith('token-billing='));
        return token ? token.split('=')[1] : null;
    };

    const authToken = checkAuthToken();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const deleteAllCookies = () => {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++)
            document.cookie = "token-billing=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`";
    };

    const handleLogout = async (response) => {
        await deleteAllCookies();
        logout(); // Update the user context
        navigate('/login'); // Redirect to the main page
    };

    useEffect(() => {
        setDrawerOpen(false);
    }, [user]);

    return (
        <nav className="navbar"  >
            <AppBar sx={{ backgroundColor: ColorScheme.background, height: 60, marginBottom: "20px", justifyContent: "center", zIndex: 0 }} position="static">
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>  
                    <Button sx={{p: "0px"}} onClick={() => navigate('/')}>
                        <img src={logo} alt="Logo" style={{ height: '20px' }} />
                    </Button>
                </Toolbar>
            </AppBar>

            {user && authToken &&
                <>
                    <Drawer sx={{ zIndex: 999 }} anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                        <Paper sx={{ width: 250, height: '100%', backgroundColor: ColorScheme.background, color: "white", borderRadius: 0, display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <List sx={{ height: "90%", flexDirection: "column", width: "100%", display: "flex", alignItems: "center", paddingTop: "90px" }}>
                                <Button onClick={() => navigate('/')} sx={{ marginBottom: "10px", justifyContent: "flex-start", alignItems: "center", display: "flex", width: "80%", height: "40px", backgroundColor: window.location.pathname === "/" && "rgba(255, 255, 255, 0.1)" }}>
                                    <Typography textTransform={"none"} style={{ color: "white", marginRight: "10px", fontWeight: '600' }}>{"Etusivu"}</Typography>
                                </Button>
                                <Button onClick={() => navigate('/contracts')} sx={{ marginBottom: "10px", justifyContent: "flex-start", alignItems: "center", display: "flex", width: "80%", height: "40px", backgroundColor: window.location.pathname === "/contracts" && "rgba(255, 255, 255, 0.1)" }}>
                                    <Typography textTransform={"none"} style={{ color: "white", marginRight: "10px", fontWeight: '600' }}>{"Sopimukset"}</Typography>
                                </Button>
                                <Button onClick={() => navigate('/invoices')} sx={{ marginBottom: "10px", justifyContent: "flex-start", alignItems: "center", display: "flex", width: "80%", height: "40px", backgroundColor: window.location.pathname === "/invoices" && "rgba(255, 255, 255, 0.1)" }}>
                                    <Typography textTransform={"none"} style={{ color: "white", marginRight: "10px", fontWeight: '600' }}>{"Laskut"}</Typography>
                                </Button>
                                <Button onClick={() => navigate('/offers')} sx={{ marginBottom: "10px", justifyContent: "flex-start", alignItems: "center", display: "flex", width: "80%", height: "40px", backgroundColor: window.location.pathname === "/offers" && "rgba(255, 255, 255, 0.1)" }}>
                                    <Typography textTransform={"none"} style={{ color: "white", marginRight: "10px", fontWeight: '600' }}>{"Tarjoukset"}</Typography>
                                </Button>
                                <Button onClick={() => navigate('/users')} sx={{ marginBottom: "10px", justifyContent: "flex-start", alignItems: "center", display: "flex", width: "80%", height: "40px", backgroundColor: window.location.pathname === "/users" && "rgba(255, 255, 255, 0.1)" }}>
                                    <Typography textTransform={"none"} style={{ color: "white", marginRight: "10px", fontWeight: '600' }}>{"Käyttäjät"}</Typography>
                                </Button>
                            </List>
                            <Button sx={{ justifyContent: "center", alignItems: "center", display: "flex", width: "70%", height: "40px" }} onClick={() => handleLogout()}>
                                <Typography textTransform={"none"} style={{ color: "white", marginRight: "10px" }}>{"Kirjaudu ulos"}</Typography>
                                <LoginIcon fill="none" stroke="white" height="18px" />
                            </Button>
                        </Paper>
                    </Drawer>
                    <Box sx={{ display: "flex", position: "absolute", zIndex: 1000, top: "10px", right: "20px" }}>
                        <HamburgerMenu setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
                    </Box>
                </>}
        </nav>
    );
};

export default Navbar;
